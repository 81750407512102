html, body {
	width: 100%;
	height: 100%;
  margin: 0;
  padding: 0;
}

/* https://www.gradient-animator.com/ */
/* https://stackoverflow.com/questions/30362194/css-gradient-animation-not-working */
body {
  background: linear-gradient(259deg, #6824f0, #fa1cac, #f274bb, #a361db, #fb1b4b);
  background-size: 1000% 1000%;
  
  -webkit-animation: AnimationName 39s ease infinite;
  -moz-animation: AnimationName 39s ease infinite;
  animation: AnimationName 39s ease infinite;
}

@-webkit-keyframes AnimationName {
  0%{background-position:0% 51%}
  50%{background-position:100% 50%}
  100%{background-position:0% 51%}
}
@-moz-keyframes AnimationName {
  0%{background-position:0% 51%}
  50%{background-position:100% 50%}
  100%{background-position:0% 51%}
}
@-o-keyframes AnimationName {
  0%{background-position:0% 51%}
  50%{background-position:100% 50%}
  100%{background-position:0% 51%}
}
@keyframes AnimationName {
  0%{background-position:0% 51%}
  50%{background-position:100% 50%}
  100%{background-position:0% 51%}
};

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.app-logo {
  height: 15vmin;
  pointer-events: none;
}

.content {
  display: flex;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#mode-dropdown-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  background-color: transparent;
  border-color: transparent;
  color: hotpink;
  font-size: 8pt;
  font-family: Lato;
  opacity: 0.5;
}

select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}

/* For IE10 */
select::-ms-expand {
  display: none;
}

.app-info {
  display: flex;
  font-size: 11pt;
  font-family: Lato;
  color: white;
}

#app-info-separator {
  width: 1px;
  height: 100%;
  border-left: 1px solid white;
  margin-left: 50px;
  margin-right: 50px;
}

#app-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

#app-description-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

#download-buttons-container {
  flex-direction: row;
}

#unsupported-country-label {
  font-size: 10pt;
  color: yellow;
}

#title-bar {
  position: fixed;
  bottom: 15vh;
  font-size: 35pt;
  font-family: Lato;
  color: white;
}

.download-button {
  background-color: white;
  border: none;
  color: gray;
  padding: 10px;
  text-align: center;
  font-size: 12pt;
  font-family: Lato;
  margin: 10px 10px;
  width: 100px;
  height: 40px;
  border-radius: 20px;
}

.bottom-toolbar {
  position: fixed;
  bottom: 10pt;
  font-size: 11pt;
  font-family: Lato;
  color: white;
  opacity: 0.7;
  text-align: center;
}

.bottom-toolbar-separator {
  margin: 5pt;
  display: inline
}

a:visited {
  color: white;
}

a:link {
  color: white;
}

/* mouse over link */
a:hover {
  color: rgb(74, 211, 253);
}

